import React, { useEffect, useState } from 'react';
import { OnlyPC, OnlySP } from '../../../helper/media-helper';

const Select: React.FunctionComponent<any> = (props) => {
  const [currentLabel, setCurrentLabel] = useState(props.label[0]);
  const [isOpen, toggleOpen] = useState(false);

  useEffect(() => {
    setCurrentLabel(props.chooseData[props.targetName]);
  }, [props.chooseData]);

  return (
    <>
      <OnlyPC>
        <div
          className="store-detail__select"
          onMouseLeave={() => {
            toggleOpen(false);
          }}>
          <p
            className="store-detail__select-trigger"
            onPointerDown={() => {
              toggleOpen(true);
            }}>
            {currentLabel}
          </p>

          <ul className={`store-detail__select-list ${isOpen ? 'active' : ''}`}>
            {props.label &&
              props.label.map((elem: any, i: number) => {
                return (
                  <li
                    className={`store-detail__select-list-item ${elem === currentLabel ? 'active' : ''}`}
                    key={`store-detail__select-list-item-${props.label[0]}-${i}`}
                    onClick={() => {
                      props.setChooseData({ ...props.chooseData, [props.targetName]: elem });
                      toggleOpen(false);
                    }}>
                    {elem}
                  </li>
                );
              })}
          </ul>
        </div>
      </OnlyPC>
      <OnlySP>
        <div className="select-wrap">
          <select
            onChange={(e) => {
              props.setChooseData({ ...props.chooseData, [props.targetName]: e.target.value });
            }}
            defaultValue={currentLabel}
            key={`item-detail-select-${currentLabel}`}>
            {props.label &&
              props.label.map((elem: any, i: number) => {
                return (
                  <option value={elem} key={`store-detail__select-list-item-${props.label[0]}-${i}`}>
                    {elem}
                  </option>
                );
              })}
          </select>
        </div>
      </OnlySP>
    </>
  );
};
export default Select;
