import React, { useContext, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Slide from '../components/store/detail/slide';
import ItemInfo from '../components/store/detail/item-info';
import RelatedItems from '../components/utils/related-items';

import '../css/store_detail.css';
import { PATH } from '../helper/config';
import { AppContext } from '../context/context';

const ProductDetail: React.FunctionComponent<any> = ({ data, pageContext }) => {
  const context = useContext(AppContext);
  const relatedItems: any[] = [];
  let localePath: string = '';
  if (context.locale === 'en_US') localePath = '/en';

  useEffect(() => {
    context.setLanguage(
      {
        ja: `${PATH.STORE_DETAIL}/${pageContext.handle}`,
        en: `/en${PATH.STORE_DETAIL}/${pageContext.handle}`
      },
      pageContext.locale
    );
  }, []);

  for (const item of data.related.edges) {
    if (item.node.vendor === pageContext.vendor && item.node.handle !== pageContext.handle) {
      relatedItems.push(item.node);
    }
  }
  return (
    <div className="store-detail util__page">
      <Helmet>
        <title>{data.shopifyProduct.title} | POST-FAKE</title>
        <meta name="Description" content={data.shopifyProduct.descriptionHtml.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '')} />
        <meta property="og:description" content={data.shopifyProduct.descriptionHtml.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '')} />
        <meta property="og:title" content={`${data.shopifyProduct.title} | POST-FAKE`} />
        <meta property="og:url" content={`https://postfake.com${context.localePath}${PATH.STORE_DETAIL}/${data.shopifyProduct.handle}`} />
        {data.shopifyProduct && data.shopifyProduct.images && data.shopifyProduct.images[0] && data.shopifyProduct.images[0].src && (
          <meta property="og:image" content={data.shopifyProduct.images[0].src} />
        )}
        <meta name="twitter:site" content={`${data.shopifyProduct.title} | POST-FAKE`} />
        <meta name="twitter:title" content={`${data.shopifyProduct.title} | POST-FAKE`} />
        <meta name="twitter:description" content={data.shopifyProduct.descriptionHtml.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '')} />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <Slide data={data.shopifyProduct.images} />
      <ItemInfo data={data.shopifyProduct} locale={pageContext.locale} />
      <RelatedItems items={relatedItems} locale={pageContext.locale} />
      <div className="util__page-mover-all">
        <Link to={`${localePath}${PATH.STORE}`} className="link__text ff-univ-bold">
          ● BACK TO ALL
        </Link>
      </div>
    </div>
  );
};
export default ProductDetail;

export const query = graphql`
  query storeDetailItemQuery($handle: String!, $vendor: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      vendor
      handle
      descriptionHtml
      images {
        src
        width
        height
      }
      options {
        values
        name
      }
      variants {
        title
        storefrontId
        availableForSale
        inventoryQuantity
        selectedOptions {
          name
          value
        }
      }
    }

    related: allShopifyProduct(filter: { vendor: { eq: $vendor } }) {
      edges {
        node {
          handle
          featuredImage {
            transformedSrc
          }
          title
          vendor
        }
      }
    }
  }
`;
