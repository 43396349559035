import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
SwiperCore.use([Autoplay, Pagination]);

import { useMediaQuery } from 'react-responsive';
import LazyImage from '../../utils/lazy-image';

const Slide: React.FunctionComponent<any> = ({ data }) => {
  const isSP: boolean = useMediaQuery({ query: `(max-width: 414px)` });

  let images = [...data];
  if (images.length === 1) images = images.concat(images);

  return (
    <div className="store-detail__slide">
      {data && (
        <>
          <div className="store-detail__slide-inner">
            <Swiper
              autoplay={{
                delay: 5000,
                disableOnInteraction: false
              }}
              slidesPerView={'auto'}
              centeredSlides={true}
              loop={true}
              loopAdditionalSlides={images.length * 5}
              spaceBetween={isSP ? 14 : 80}
              pagination={{
                el: '.store-detail__slide-navigation',
                clickable: true,
                renderBullet: (index, className) => {
                  return '<span class="' + className + ' link__alpha">' + (index + 1) + '</span>';
                }
              }}>
              {images &&
                images.map((elem: any, i: number) => {
                  return (
                    <SwiperSlide key={`store-detail-slide-${i}`}>
                      <LazyImage rootMargin={'100%'} src={elem.src} width={elem.width} height={elem.height} alt={''} />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
          <div className="store-detail__slide-navigation swiper-pagination ff-mono"></div>
        </>
      )}
    </div>
  );
};
export default Slide;
