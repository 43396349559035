import React, { useContext, useEffect, useState } from 'react';
import { GraphQLClient, gql } from 'graphql-request';
import { Link } from 'gatsby';
import { PATH } from '../../../helper/config';

import Select from './select';
import Util from '../../../templates/utils/util';
import API from '../../../templates/utils/api';
import { AppContext } from '../../../context/context';

const ItemInfo: React.FunctionComponent<any> = ({ data, locale }) => {
  const context = useContext(AppContext);

  const [price, setPrice] = useState(null);
  const [chooseData, setChooseData] = useState<any>({});
  const [amount, setAmount] = useState<number>(1);
  const [variantsNo, setVariants] = useState<number>(0);
  const [id, setId] = useState<string>('');

  const pricePrefix: string = locale === 'en_US' ? '$' : '¥';

  useEffect(() => {
    let tempData = null;

    labelFor: for (const elem of data.variants) {
      if (elem.availableForSale) {
        tempData = {};
        elem.selectedOptions.map((node) => {
          tempData = { ...tempData, [node.name]: node.value };
        });
        break labelFor;
      }
    }

    if (!tempData) {
      tempData = {};
      data.options.map((elem: any) => {
        tempData = { ...tempData, [elem.name]: elem.values[0] };
      });
    }

    setChooseData({ ...tempData });
    loadPriceData();
  }, []);

  useEffect(() => {
    for (let i: number = 0; i < data.variants.length; i++) {
      const node = data.variants[i];
      let refObj = {};
      for (const option of node.selectedOptions) {
        refObj = { ...refObj, [option.name]: option.value };
      }

      let isMatch: boolean = true;

      Object.keys(refObj).forEach((key) => {
        if (!chooseData[key]) isMatch = false;
        if (chooseData[key] !== refObj[key]) isMatch = false;
      });

      if (isMatch) {
        setVariants(i);
      }
    }
  }, [chooseData]);

  useEffect(() => {
    setAmount(1);
    setId(data.variants[variantsNo].storefrontId);
  }, [variantsNo]);

  const loadPriceData = async (): Promise<any> => {
    const endpoint = API.STORE_FRONT;
    const graphQLClient = new GraphQLClient(endpoint, {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': API.STORE_FRONT_TOKEN,
        'Accept-Language': locale === 'en_US' ? 'en-US' : 'ja-jp'
      }
    });

    const queryJa = gql`
      query productDetailItemPriceQuery($handle: String!, $count: Int!) @inContext(country: JP) {
        productByHandle(handle: $handle) {
          variants(first: $count) {
            edges {
              node {
                compareAtPriceV2 {
                  amount
                }
                priceV2 {
                  amount
                }
                title
              }
            }
          }
        }
      }
    `;
    const queryEn = gql`
      query productDetailItemPriceQuery($handle: String!, $count: Int!) @inContext(country: US) {
        productByHandle(handle: $handle) {
          variants(first: $count) {
            edges {
              node {
                compareAtPriceV2 {
                  amount
                }
                priceV2 {
                  amount
                }
                title
              }
            }
          }
        }
      }
    `;

    const variables = {
      handle: data.handle,
      count: data.variants.length
    };
    const result = await graphQLClient.request(locale === 'en_US' ? queryEn : queryJa, variables);
    setPrice([...result.productByHandle.variants.edges]);
  };

  const addCart = async (): Promise<any> => {
    API.CART_CONNECT = true;
    const endpoint = API.STORE_FRONT;
    const graphQLClient = new GraphQLClient(endpoint, {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': API.STORE_FRONT_TOKEN,
        'Accept-Language': !context.localePath.length ? 'ja-jp' : 'en-US'
      }
    });

    const queryJa = gql`
      mutation createCart($cartInput: CartInput) @inContext(country: JP) {
        cartCreate(input: $cartInput) {
          cart {
            id
            lines(first: 50) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      image {
                        height
                        width
                        transformedSrc
                      }
                      priceV2 {
                        amount
                        currencyCode
                      }
                      quantityAvailable
                      title
                      product {
                        handle
                        title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    const queryEn = gql`
      mutation createCart($cartInput: CartInput) @inContext(country: US) {
        cartCreate(input: $cartInput) {
          cart {
            id
            lines(first: 50) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      image {
                        height
                        width
                        transformedSrc
                      }
                      priceV2 {
                        amount
                        currencyCode
                      }
                      quantityAvailable
                      title
                      product {
                        handle
                        title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const lines: any[] = [];

    if (!context.cartData.lines) {
      localStorage.removeItem('cart_id');
      window.location.reload();
      return;
    }
    if (context.cartData.lines.edges) {
      for (const edge of context.cartData.lines.edges) {
        lines.push({
          quantity: edge.node.quantity,
          merchandiseId: edge.node.merchandise.id
        });
      }
    }

    let alreadyInCart: boolean = false;

    for (const item of lines) {
      if (item.merchandiseId === id) {
        item.quantity += amount;
        alreadyInCart = true;
      }
    }

    if (!alreadyInCart) {
      lines.push({
        quantity: amount,
        merchandiseId: id
      });
    }

    const variables = {
      cartInput: {
        buyerIdentity: { countryCode: !context.localePath.length ? 'JP' : 'US' },
        lines: lines
      }
    };

    const data = await graphQLClient.request(!context.localePath.length ? queryJa : queryEn, variables);
    localStorage.setItem('cart_id', data.cartCreate.cart.id);
    context.setCartID(data.cartCreate.cart.id);
    context.setCartData({ ...data.cartCreate.cart });
    context.toggleCartWindow(true);

    API.CART_CONNECT = false;
  };

  return (
    <section className="store-detail__item-info">
      <div className="store-detail__item-info-row">
        <div className="store-detail__item-info-column store-detail__item-info-column--left">
          <h1 className="store-detail__item-info-caption ff-univ-bold">{data.title}</h1>
          <p className="store-detail__item-info-artist ff-mono">
            by{' '}
            <Link
              className="link__alpha"
              to={`${!context.localePath.length ? '' : '/en'}${PATH.STORE}artist/${Util.GET_VENDOR(data.vendor).en}`}>
              {!context.localePath.length ? Util.GET_VENDOR(data.vendor).ja : Util.GET_VENDOR(data.vendor).en}
            </Link>
          </p>

          {!price && (
            <div className="store-detail__item-info-price ff-univ">
              <span className="store-detail__item-info-price-primary">&nbsp;</span>
            </div>
          )}
          {price && (
            <div className="store-detail__item-info-price ff-univ">
              {price[variantsNo].node.compareAtPriceV2 && (
                <span className="store-detail__item-info-price-secondary">
                  {pricePrefix}
                  {Util.getPriceString(Number(price[variantsNo].node.compareAtPriceV2.amount))}
                </span>
              )}

              <span className="store-detail__item-info-price-primary">
                {pricePrefix}
                {Util.getPriceString(price[variantsNo].node.priceV2.amount)}
              </span>
              <span className="store-detail__item-info-price-tax ff-mono">(incl. tax)</span>
            </div>
          )}

          <div className="store-detail__item-info-form">
            <div className="store-detail__item-info-form-column">
              <div className="store-detail__item-info-form-column-column">
                {data.options &&
                  data.options.map((elem: any, i: number) => {
                    if (elem.values && elem.values[0] !== 'Default Title') {
                      return (
                        <Select
                          key={`project-detail-select-${i}`}
                          label={elem.values}
                          targetName={elem.name}
                          chooseData={chooseData}
                          setChooseData={setChooseData}
                        />
                      );
                    }
                  })}
              </div>

              {data.variants[variantsNo].availableForSale && (
                <div className="amount ff-univ">
                  <p
                    className="amount-minus link__alpha"
                    onClick={() => {
                      setAmount(Math.max(1, amount - 1));
                    }}>
                    -
                  </p>
                  <p className="amount-value">{amount}</p>

                  {amount < data.variants[variantsNo].inventoryQuantity && (
                    <p
                      className="amount-plus link__alpha"
                      onClick={() => {
                        setAmount(amount + 1);
                      }}>
                      +
                    </p>
                  )}
                </div>
              )}
            </div>

            {data.variants[variantsNo].availableForSale && (
              <p
                className="store-detail__item-info-submit ff-univ-bold link__round-fill"
                onClick={() => {
                  addCart();
                }}>
                <span>ADD TO CART</span>
              </p>
            )}
            {!data.variants[variantsNo].availableForSale && (
              <p className="store-detail__item-info-submit store-detail__item-info-submit--sold ff-univ-bold">SOLD OUT</p>
            )}
          </div>
        </div>

        <div className="store-detail__item-info-column store-detail__item-info-column--right">
          <div className="store-detail__item-info-description" dangerouslySetInnerHTML={{ __html: data.descriptionHtml }}></div>
        </div>
      </div>
    </section>
  );
};
export default ItemInfo;
