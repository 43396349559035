import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import LazyImage from './lazy-image';
import Util from '../../templates/utils/util';
import { AppContext } from '../../context/context';
import { PATH } from '../../helper/config';
import { GraphQLClient, gql } from 'graphql-request';
import API from '../../templates/utils/api';
import { OnlyPC } from '../../helper/media-helper';

const RelatedItems: React.FunctionComponent<any> = (props) => {
  const context = useContext(AppContext);
  const pricePrefix = context.localePath.length ? '$' : '¥';

  if (props.items && props.items.length) {
    return (
      <div className="util__related-items">
        <ul className="util__related-items-list">
          {props.items &&
            props.items.map((elem: any, i: number) => {
              return <RelatedItemNode key={`store_detail_related_item_${elem.handle}`} data={elem} locale={props.locale} />;
            })}
        </ul>
      </div>
    );
  } else if (props.data && props.data.length) {
    return (
      <div className="util__related-items">
        <ul className="util__related-items-list">
          {props.data &&
            props.data.map((elem: any, i: number) => {
              if (elem.url) {
                return (
                  <li className="util__related-items-list-item ff-univ" key={`util__related-items-list-item-${i}`}>
                    <a href={elem.url}>
                      <figure className="util__related-items-list-item-figure">
                        {elem.image && <LazyImage className="fit fit--contain" data={elem.image} />}
                      </figure>
                      <div className="util__related-items-list-item-meta">
                        <p className="util__related-items-list-item-name">
                          {elem.title}
                          <br />
                          by {elem.name}
                        </p>
                        <p className="util__related-items-list-item-price ff-mono">
                          {pricePrefix}
                          {elem.price}
                        </p>
                      </div>
                    </a>
                    <OnlyPC>
                      <div className="store__list-item-hover ff-univ-bold">
                        <span className="store__list-item-hover-top">{elem.name}</span>
                        <span className="store__list-item-hover-bottom">
                          <span>View more</span>
                          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="13" viewBox="0 0 80 13">
                            <path fill="#fff" d="M80 6.5L59 13V0z" />
                            <path fill="#fff" d="M51 6.5L29 13V0z" />
                            <path fill="#fff" d="M21 6.5L0 13V0z" />
                          </svg>
                        </span>
                      </div>
                    </OnlyPC>
                  </li>
                );
              }
            })}
        </ul>
      </div>
    );
  } else {
    return <></>;
  }
};
export default RelatedItems;

const RelatedItemNode: React.FunctionComponent<any> = ({ data, locale }) => {
  const [price, setPrice] = useState(null);

  const context = useContext(AppContext);
  const elem = data;
  const pricePrefix = context.localePath.length ? '$' : '¥';

  useEffect(() => {
    loadPrice();
  }, []);

  const loadPrice = async (): Promise<any> => {
    const endpoint = API.STORE_FRONT;
    const graphQLClient = new GraphQLClient(endpoint, {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': API.STORE_FRONT_TOKEN
      }
    });

    const query = gql`
      query storeRelatedListItemQuery($handle: String!) @inContext(country: JP) {
        productByHandle(handle: $handle) {
          variants(first: 1) {
            edges {
              node {
                compareAtPriceV2 {
                  amount
                  currencyCode
                }
                priceV2 {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
      }
    `;
    const queryEn = gql`
      query storeRelatedListItemQuery($handle: String!) @inContext(country: US) {
        productByHandle(handle: $handle) {
          variants(first: 1) {
            edges {
              node {
                compareAtPriceV2 {
                  amount
                }
                priceV2 {
                  amount
                }
              }
            }
          }
        }
      }
    `;
    const variables = {
      handle: elem.handle
    };
    const result = await graphQLClient.request(locale === 'en_US' ? queryEn : query, variables);
    setPrice({ ...result.productByHandle.variants.edges[0].node.priceV2 });
  };

  return (
    <li className="util__related-items-list-item ff-univ">
      <a href={`${context.localePath}${PATH.STORE_DETAIL}/${elem.handle}`}>
        <figure className="util__related-items-list-item-figure">
          {elem.featuredImage && <LazyImage className="fit fit--contain" data={{ mediaItemUrl: elem.featuredImage.transformedSrc }} />}
        </figure>
        <div className="util__related-items-list-item-meta">
          <p className="util__related-items-list-item-name">
            {elem.title}
            <br />
            by {context.locale === 'en_US' ? Util.GET_VENDOR(elem.vendor).en : Util.GET_VENDOR(elem.vendor).ja}
          </p>
          {price && (
            <p className="util__related-items-list-item-price ff-mono">
              {pricePrefix}
              {Util.getPriceString(Number(price.amount))}
            </p>
          )}
        </div>
      </a>
      <OnlyPC>
        <div className="store__list-item-hover ff-univ-bold">
          <span className="store__list-item-hover-top">{Util.GET_VENDOR(elem.vendor).en}</span>
          <span className="store__list-item-hover-bottom">
            <span>View more</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="13" viewBox="0 0 80 13">
              <path fill="#fff" d="M80 6.5L59 13V0z" />
              <path fill="#fff" d="M51 6.5L29 13V0z" />
              <path fill="#fff" d="M21 6.5L0 13V0z" />
            </svg>
          </span>
        </div>
      </OnlyPC>
    </li>
  );
};
